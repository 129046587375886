var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var mousedownHandler = ref.mousedownHandler;
      var focusHandler = ref.focusHandler;
      var blurHandler = ref.blurHandler;
      var keydownHandler = ref.keydownHandler;
return [_c('button',{class:("trip-status-pill-button " + _vm.getVariant + " focus:outline-none"),staticStyle:{"height":"35px","width":"170px"},attrs:{"aria-label":"Trip Completed Dropdown","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('span',[_vm._v(_vm._s(_vm.getText))]),(_vm.$acl.canUpdate('transaction'))?[(!_vm.refundStatus)?_c('i',{staticClass:"fas fa-chevron-down"}):_vm._e()]:_vm._e()],2)]}},(!_vm.refundStatus || !_vm.penalizedStatus)?{key:"default",fn:function(ref){
      var hide = ref.hide;
return [(_vm.$acl.canUpdate('transaction'))?_c('div',{staticClass:"py-1 mt-1 bg-white rounded-md shadow-md",on:{"click":function($event){return hide()}}},[(!_vm.refundStatus)?_c('t-dropdown-item',{staticClass:"text-oOrange hover:bg-orange-100",on:{"click":function($event){return _vm.$emit('refund')}}},[_vm._v(" "+_vm._s(_vm.$t('components.tripManagement.details.headline.refund'))+" ")]):_vm._e(),(!_vm.penalizedStatus)?_c('t-dropdown-item',{staticClass:"text-oRed hover:bg-red-100",on:{"click":function($event){return _vm.$emit('penalize-policy')}}},[_vm._v(" "+_vm._s(_vm.$t('components.tripManagement.details.headline.policyPenalty'))+" ")]):_vm._e()],1):_vm._e()]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }