<template>
  <div class="flex items-center">
    <img
      v-if="item.id === 'co2'"
      class="w-7 h-7"
      src="@/assets/icon/co2-save.svg"
      alt="co2"
    />
    <i v-else :class="item.icon_class" :style="'color:' + item.color"></i>
    <div class="ml-4">
      <span class="block grid-text">{{ item.title }}</span>
      <span v-if="item.id === 'co2'" class="block grid-sub-text">
        CO<sub>2</sub>
        {{ this.$t('components.tripManagement.details.map.saved') }}
      </span>
      <span
        v-else
        class="block grid-sub-text"
        :style="`max-width : ${width}px;`"
        >{{ item.subTitle }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      required: false,
    },
    width: {
      required: false,
    },
  },
}
</script>
