<template>
  <section class="mb-2 bg-white rounded-md shadow h-full">
    <div class="responsive-point">
      <div
        class="absolute flex items-center w-full h-full place-content-center"
      >
        <div>
          <span class="block cost-total-title">{{ total_count }}</span>
          <span class="block cost-sub-title">
            {{
              $t('components.tripManagement.details.tripPoint.pointEarned')
            }}</span
          >
        </div>
      </div>
      <div
        class="point-box"
        :style="`background-color:${total_count >= 0 ? '#e4fdf9' : '#fbe6e6'}`"
      ></div>
    </div>
    <div
      class="cost-list responsive-point-categories"
      v-for="(category, index) in point_categories"
      :key="index"
    >
      <div class="w-4/12">
        <span class="cost-title">{{ category.name }}</span>
      </div>
      <div class="flex justify-center w-5/12">
        <span class="cost-id"
          ><a href="">{{ category.id }}</a></span
        >
      </div>
      <div class="flex justify-center w-3/12">
        <span
          class="text-center cost-val"
          :style="category.price < 0 ? 'color:#ff0000' : 'color:#000000'"
          >{{ category.price }}</span
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  props: ['detail'],
  mounted() {
    console.log(this.detail.trip_points)
    var arr = []
    var count = 0
    var reduce_to_zero_found = false
    this.detail.trip_points.forEach((item) => {
      if (item.reduce_to_zero) {
        reduce_to_zero_found = true
        count = 0
      } else if (!reduce_to_zero_found) {
        count += parseInt(item.point)
      }
      var obj = {
        price: item.reduce_to_zero
          ? 'Reduced to 0'
          : parseInt(item.point).toFixed(),
        name: item.title,
        id: `#${item.id ? item.id.substr(item.id.length - 5) : '--'}`,
      }
      arr.push(obj)
    })

    this.point_categories = arr
    this.total_count = parseInt(count).toFixed()
  },
  data() {
    return {
      total_count: 0,
      point_categories: [
        {
          name: 'Trip Completed',
          price: '+7',
          id: '',
        },
        {
          name: 'Trip Completed',
          price: '-1',
          id: 'Report ID: #533453',
        },
        {
          name: 'Trip Completed',
          price: '-1',
          id: 'Report ID: #565657',
        },
      ],
    }
  },
  computed: {
    getTotalPoints() {
      return parseInt(this.count).toFixed()
    },
  },
}
</script>

<style scoped>
.point-box {
  width: 100%;
  height: 130px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
</style>
