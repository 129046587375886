<template>
  <!-- notes -->
  <div class="py-7 px-8 mb-2 mr-3 bg-white rounded-md shadow">
    <p class="text-lg font-extrabold">
      {{ $t('components.tripManagement.details.tripNotes.notes') }}
    </p>
    <QuillEditor
      v-if="$acl.canUpdate('trips')"
      v-model="content"
      :placeholder="'Add notes..'"
      @save="onSaveNote($event, id)"
    />

    <div class="flex justify-between items-center mt-8 mb-2">
      <div class="flex gap-3 items-center">
        <i class="text-sm fas fa-comment-alt"></i>
        <span class="font-bold text-14px">
          {{
            $t('components.tripManagement.details.tripNotes.latestNotes')
          }}</span
        >
      </div>
    </div>

    <template v-if="notes && notes.data.length > 0">
      <NoteItem
        v-for="note in notes.data"
        :key="note.id"
        :data="note"
        :deletePermission="$acl.canDelete('trips')"
        @delete="onDeleteNote($event, id)"
      />
    </template>

    <template v-else>
      <p class="mt-5 text-sm text-gray-500">
        {{ $t('components.tripManagement.details.tripNotes.noNotes') }}
      </p>
    </template>
  </div>
  <!-- /notes -->
</template>

<script>
import QuillEditor from '@/components/form/QuillEditor.vue'
import NoteItem from './NoteItem'
import { useEndpoints } from '@/composables'
export default {
  props: ['id'],
  data() {
    return {
      notes: null,
      content: '',
    }
  },
  components: { NoteItem, QuillEditor },
  created() {
    this.fetchNotes()
  },
  methods: {
    async fetchNotes() {
      let id = this.id

      await this.$http
        .get(useEndpoints.note.index(id, 'trip'))
        .then((res) => {
          console.log('Note = ', res.data)
          this.notes = res.data
        })
        .catch((err) => {
          console.log('err = ', err)
        })
    },
    async onSaveNote(e, tripId) {
      console.log({ onSaveNote: e })

      const url = `/dashboard/entity-notes/trip/${tripId}/`
      const data = new FormData()
      data.append('details', this.content)

      await this.$http.post(url, data).then(({ data }) => {
        this.notes.data.unshift(data)
        this.content = ''
      })
    },
    async onDeleteNote(note, parentId) {
      console.log({ parentId })
      const url = `/dashboard/entity-notes/${note.id}/`
      const result = await this.$http.delete(url)
      if (result) {
        let index = this.notes.data.findIndex((row) => row.id == note.id)
        this.notes.data.splice(index, 1)
      }
    },
  },
}
</script>
