<template>
  <div class="py-3 px-8 mb-2 mr-3 bg-white rounded-md shadow w-full">
    <div class="flex items-center mb-4">
      <img src="@/assets/icon/tag.svg" class="w-5 h-5" />
      <p class="text-lg font-extrabold ml-2">
        {{ $t('components.tripManagement.details.tags') }}
      </p>
    </div>

    <OtoTag
      :entity-id="id"
      :entity-type="'trip'"
      :editPermissionStatus="$acl.canNotUpdate('trips')"
    />
  </div>
</template>

<script>
import OtoTag from '@/composites/tag/OtoTag.vue'
export default {
  props: ['id'],
  components: {
    OtoTag,
  },
  setup() {},
}
</script>
